import { useEffect } from 'react';

import loadable from '@loadable/component';
import classNames from 'classnames';

import BpkIconAlert from '@skyscanner/backpack-web/bpk-component-icon/lg/alert--active';
import BpkIconCalendar from '@skyscanner/backpack-web/bpk-component-icon/lg/calendar';
import BpkIconCars from '@skyscanner/backpack-web/bpk-component-icon/lg/cars';
import BpkIconFlight from '@skyscanner/backpack-web/bpk-component-icon/lg/flight';
import BpkIconHotelFlexible from '@skyscanner/backpack-web/bpk-component-icon/lg/hotel-flexible';
import BpkIconHotels from '@skyscanner/backpack-web/bpk-component-icon/lg/hotels';
import BpkIconPriceTag from '@skyscanner/backpack-web/bpk-component-icon/lg/price-tag';
import BpkIconSearch from '@skyscanner/backpack-web/bpk-component-icon/lg/search';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import { ACTION_TYPE, LOAD_STATUS, COMPONENT_NAME } from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';
import withErrorBoundary from '../ErrorBoundary';

import type { ConfidenceMessagingProps } from 'common-types/types/ConfidenceMessaging';

import STYLES from './ConfidenceMessaging.module.scss';

const IntersectionObserverWrapper = loadable(
  () => import('../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

type ConfidenceMessagingIcon =
  ConfidenceMessagingProps['dataList'][number]['iconName'];

const getIconComponent = (iconName: ConfidenceMessagingIcon) => {
  switch (iconName) {
    case 'flight':
      return BpkIconFlight;
    case 'cars':
      return BpkIconCars;
    case 'hotels':
      return BpkIconHotels;
    case 'calendar':
      return BpkIconCalendar;
    case 'price-tag':
      return BpkIconPriceTag;
    case 'search':
      return BpkIconSearch;
    case 'hotel-flexible':
      return BpkIconHotelFlexible;
    case 'alert':
      return BpkIconAlert;
    default:
      throw Error(`Icon component not defined for icon name "${iconName}"`);
  }
};

const ConfidenceMessaging = (props: ConfidenceMessagingProps) => {
  useEffect(() => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_LOADED,
      component_name: COMPONENT_NAME.CONFIDENCE_MESSAGING,
      load_status: props?.dataList?.length
        ? LOAD_STATUS.LOADED
        : LOAD_STATUS.INVALID,
    });
  }, [props?.dataList]);

  if (!props?.dataList?.length) {
    return null;
  }

  logMiniEventHelper({
    action_type: ACTION_TYPE.COMPONENT_LOADED,
    component_name: COMPONENT_NAME.CONFIDENCE_MESSAGING,
    load_status: LOAD_STATUS.LOADED,
  });

  const useVerticalLayout = !!props.useVerticalLayout;
  const debug = !!props.debug;

  return (
    <div
      className={classNames(STYLES.ConfidenceMessaging, {
        [STYLES.debug]: debug,
        [STYLES.vertical]: useVerticalLayout,
      })}
      data-testid="confidenceMessaging"
    >
      {props.dataList.map((item) => {
        const Icon = getIconComponent(item.iconName);
        return (
          <div key={item.iconName} className={STYLES.item}>
            <div className={STYLES.icon}>
              <Icon />
            </div>
            <div>
              <BpkText
                className={STYLES.description}
                tagName="p"
                textStyle={TEXT_STYLES.label1}
              >
                {item.description}
              </BpkText>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ConfidenceMessagingWrapper = (props: ConfidenceMessagingProps) => (
  <IntersectionObserverWrapper
    onElementSeen={() =>
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_VISIBLE,
        component_name: COMPONENT_NAME.CONFIDENCE_MESSAGING,
      })
    }
  >
    <ConfidenceMessaging {...props} />
  </IntersectionObserverWrapper>
);

const ConfidenceMessagingWithErrorBoundary = withErrorBoundary(
  ConfidenceMessagingWrapper,
  'confidence-messaging-component',
);

export default ConfidenceMessagingWithErrorBoundary;
